@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
@import "src/app/pages/.styles/button";

$button-border-radius: 5px !default;

.home-smartDP-search-input {
	position: relative;

	&__content {
		position: relative;
		box-sizing: border-box;
		width: 100%;
		height: 56px;
		background: white;
		border-radius: $button-border-radius;
	}

	&__icon {
		&-left {
			width: 18px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 16px;
			bottom: 0;
			display: flex;
			align-items: center;
			color: black;

			svg {
				width: 18px;
			}
		}

		&-right {
			@extend %button--primary;

			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			min-height: unset;
			position: absolute;
			top: 8px;
			right: 8px;

			svg {
				width: 18px;
			}
		}
	}

	&__label {
		left: 52px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		display: flex;
		align-items: center;
		transition: top 0.1s ease-out, font-size 0.1s ease-out;
		pointer-events: none; // fix empeche de saisir sur device ios
		font-size: 16px;
	}

	&-side-panel__content {
		.sdp-search-form {
			&__form {
				padding-left: 16px;
				width: calc(100% - 16px);
			}

			&__field {
				border: 1px solid $grey-medium;
			}
		}
	}
}
