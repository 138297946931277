@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.location-menu-list {
	&__top-departure-cities-label {
		color: $color-primary;
		padding: 24px 16px;
		
		@include gridle_state(md) {
			padding: 24px;
		}
	}

	.advanced-select-option {
		@include gridle_state(md) {
			padding-left: 24px;
			padding-right: 24px;
		}
	}

	&--extension {
		display: flex;
		padding-bottom: 0;
		max-height: unset;

		.location-menu-list__top-departure-cities-label {
			@include gridle_state(md) {
				font-size: 1.6rem;
				line-height: 120%;
			}
		}
	}

	&__options {
		width: 318px;
	}

	&__extend-container {
		padding: 32px 24px;
		width: 376px;
	}
}
