@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$border-radius-big: 5px !default;

%active-state {
	color: white;
	background: $active-color;
	border-radius: 100%;
	position: relative;
	padding: 0;
}

%select-range-support {
	content: "";
	position: absolute;
	top: 0;
	background: $grey-light;
	width: 50%;
	height: 100%;
	z-index: -1;
}

.date-calendar-input {
	$this: &;

	position: relative;
	box-sizing: border-box;

	&:focus-visible {
		outline: none; // pas de bordure bleu au focus via tab clavier
	}

	&__close_icon {
		position: absolute;
		right: 16px;
		top: 39px;
		color: #ccc;
	}

	&__label {
		position: absolute;
		top: 18px;
		left: 15px;
		display: flex;
		align-items: center;
		transition: top 0.1s ease-out, font-size 0.1s ease-out;
		color: $grey-dark;
		pointer-events: none; // fix empeche de saisir sur device ios

		@include gridle_state(md) {
			top: 15px;
		}
	}

	&__input {
		@extend %font-regular;

		height: 50px;
		width: 100%;
		padding-top: 10px;
		padding-bottom: 0; // the content is not centered on ios
		padding-left: 15px;
		box-sizing: border-box;
		border: 1px solid $input-border-color;
		font-size: 1.4rem;
		outline: none;
		appearance: none;
		border-radius: $input-border-radius;
		box-shadow: $input-shadow-box;
		-webkit-appearance: none; // delete top big border in ios
		display: flex;
		align-items: center;
		background: white;
	}

	&__placeholder {
		color: $grey-dark;
	}

	&__dash {
		margin-left: 3px;
		margin-right: 3px;
	}

	&--touched {
		#{$this}__label {
			@extend %input-label-touched;

			top: 10px;

			@include gridle_state(md) {
				top: 6px;
			}
		}
	}

	&--error {
		#{$this}__label {
			@extend %font-bold;

			color: $color-error;
		}
	}

	&--with-icon {
		#{$this}__label {
			left: 56px;
			padding-left: 0;
			@include gridle_state(md) {
				left: 50px;
			}
		}

		#{$this}__input {
			padding-left: 55px;
			@include gridle_state(md) {
				padding-left: 50px;
			}
		}

		#{$this}__icon {
			width: 24px;
			position: absolute;
			top: 0;
			left: 16px;
			bottom: 0;
			display: flex;
			align-items: center;

			@include gridle_state(md) {
				width: 18px;
			}

			svg {
				width: 24px;
				@include gridle_state(md) {
					width: 18px;
				}
			}
		}
	}

	&__popover {
		width: 1024px;
		box-sizing: border-box;
		background: white;
		padding: 25px 100px;
		z-index: 12;
		border-radius: $border-radius-big;
		box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
	}

	&__calendar {
		.react-calendar__navigation__next2-button,
		.react-calendar__navigation__prev2-button {
			display: none;
		}

		.react-calendar__viewContainer {
			display: flex;
			justify-content: space-between;
			gap: 50px;
		}

		.react-calendar__month-view__days__day--neighboringMonth {
			visibility: hidden;
		}

		.react-calendar__navigation__label {
			text-transform: capitalize;
			display: flex;
			gap: 50px;
			padding-left: 0;
			padding-right: 0;
			align-items: center;
			justify-content: center;
			pointer-events: none;
			background: none;
			border: none;
			outline: none;
		}

		.react-calendar__navigation {
			display: flex;
			align-items: center;
			margin-bottom: 16px;
		}

		.react-calendar__navigation__arrow {
			background: none;
			border: none;
			cursor: pointer;
			padding-left: 0;
			padding-right: 0;

			svg {
				height: 27px;
				width: 27px;
				color: $color-primary;
			}

			&:disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
		}

		.react-calendar__navigation__label__divider {
			display: none;
		}

		.react-calendar__navigation__label__labelText {
			@extend %h4;

			width: 50%;
		}

		.react-calendar__navigation__prev-button {
			position: absolute;
			left: 50px;
			top: 45%;
		}

		.react-calendar__navigation__next-button {
			position: absolute;
			right: 50px;
			top: 45%;
		}

		.react-calendar__month-view__weekdays {
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			box-sizing: border-box;
			border-top: 1px solid $grey-light;
			border-bottom: 1px solid $grey-light;
			margin-bottom: 10px;
			padding: 16px 0;

			&__weekday {
				@extend %font-bold;

				text-align: center;
				width: 36px;
				color: $grey-dark;

				abbr {
					text-decoration: none;
				}
			}
		}

		.date-calendar-input-tile {
			padding-right: 10px;
			padding-left: 10px;

			&__tile {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin: 4px 0;
				cursor: pointer;
				border: none;
				background: none;
				color: $black;
				font-size: 1.2rem;
				box-sizing: border-box;
				aspect-ratio: 1/1;

				abbr {
					width: 100%;
					border-radius: 50%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				&[disabled] {
					pointer-events: none;
					color: $grey-medium;
				}

				&.react-calendar__tile--hover {
					background: $grey-light;

					&End {
						border-top-right-radius: 50%;
						border-bottom-right-radius: 50%;
					}
				}

				&.react-calendar__tile--range {
					background: $grey-light;

					.date-picker-calendar-tile__price,
					.date-picker-calendar-tile__price--alternative {
						display: none;
					}

					&.react-calendar__tile--hasActive {
						color: white;

						.date-picker-calendar-tile__price {
							.amount {
								color: white;
							}

							&:not(.date-picker-calendar-tile__price--best) {
								.amount__value {
									color: white;
								}
							}

							&--public {
								.amount__value {
									color: white;
								}
							}
						}
					}

					&.react-calendar__tile--rangeStart {
						@extend %active-state;

						overflow: visible !important;
						z-index: 3;

						&::before {
							@extend %select-range-support;

							right: 0;
						}
					}

					&.react-calendar__tile--rangeEnd {
						@extend %active-state;

						overflow: visible !important;
						z-index: 3;

						&::before {
							@extend %select-range-support;

							left: 0;
						}
					}

					&.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd {
						&::before {
							display: none;
						}

						&.react-calendar__tile--hover {
							&::before {
								display: block;
								transform: translateX(100%);
							}
						}
					}
				}
			}
		}
	}
}
