@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.location-inspiration {
	display: flex;
	flex-direction: column;

	&__title {
		@extend %text-bold;

		padding: 16px 0;
		font-size: 1.6rem;
		line-height: 120%;
		color: $black;
		text-align: center;

		@include gridle_state(md) {
			padding: 24px;
			text-align: left;
		}
	}

	&__merch {
		padding: 0 24px;
		margin-bottom: 16px;

		&-cta {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			width: 343px;
			height: 98px;
			overflow: hidden;
			cursor: pointer;

			@include gridle_state(md) {
				width: 328px;
			}
		}

		&-title {
			@extend %text-bold;

			position: absolute;
			display: flex;
			align-items: center;
			font-size: 1.8rem;
			line-height: 120%;
			color: white;
			text-transform: uppercase;
			max-width: 343px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;

			@include gridle_state(md) {
				max-width: 328px;
				padding: 0 10px;
			}

            &--long {
                text-align: center;
                flex-direction: column;

                .location-inspiration__merch-icon {
                    margin: 0;
                }
            }
		}

		&-icon {
			margin-left: 8px;
		}

		.background-progressive-image {
			width: 343px;
			height: 98px;

			@include gridle_state(md) {
				width: 328px;
			}

			border-radius: $border-radius-big;
			overflow: hidden;
		}
	}

	&__sales {
		padding: 0 24px 32px;

		&-list {
			display: flex;
			flex-direction: column;

			.lookalike-product {
				width: 328px;
				height: 98px;
				margin-bottom: 16px;

				&:last-child {
					margin-bottom: 0;
				}

				.product-deal__exclusive {
					color: $amount-color;
					font-size: 14px;
					letter-spacing: 0;
				}
			}
		}
	}
}
