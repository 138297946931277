@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.sdp-search-form {
	&__header {
		padding: 17px 16px;
		display: flex;
		align-items: center;
		color: $header-text-color;
		height: 60px;
		box-sizing: border-box;
		justify-content: space-between;
		box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
		margin-bottom: 24px;
		background-color: $header-background-color;
		z-index: 2;

		&-icon {
			height: 24px;
		}
	}

	&__back {
		display: flex;
		align-items: center;

		svg {
			height: 15px;
			width: 15px;
		}
	}

	&__title {
		@extend %font-bold;

		font-size: 2.2rem;
	}

	&__reset {
		min-width: 15px;
	}

	&__form {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		box-sizing: border-box;
		@include gridle_state(md) {
			justify-content: unset;
			flex-wrap: nowrap;
			gap: unset;
			flex: auto;
		}

		width: 100%;
		border-radius: $border-radius-big;

		#departureCity {
			width: 100%;

			@include gridle_state(md) {
				flex: 1.3;
				width: unset;
			}
		}

		#destinationResort {
			width: 100%;

			@include gridle_state(md) {
				flex: 1.3;
				width: unset;
			}
		}

		#travellersRooms {
			@include gridle_state(md) {
				width: 200px;
				flex: unset;
			}
		}

		#cabin {
			@include gridle_state(md) {
				flex: 0.85;
				width: unset;
			}
		}

		.advanced-select__icon,
		.date-calendar-input__icon,
		.travellers-room-button__icon {
			color: $color-primary;
			width: 24px;

			svg {
				width: 24px;
				max-height: 24px;
			}
		}

		.advanced-select--error {
			.advanced-select__select {
				box-shadow: none;
				border: none;
				@include gridle_state(md) {
					border-radius: $border-radius-big;
				}
			}
		}
	}

	&__field {
		margin-bottom: 16px;
		width: 100%;
		height: 56px;
		background: white;
		border-radius: $input-border-radius;

		@include gridle_state(md) {
			height: 45px;
			margin-bottom: 0;
			min-width: 0;
			width: unset;
			flex: 1;
			border-left: 1px solid $grey-light;
			border-radius: 0;
			cursor: pointer;

			+ .date-calendar-input {
				flex: 1.3;
			}

			&:focus-within,
			&.date-calendar-input--opened,
			&.travellers-room-button--opened {
				background-color: $grey-light;
				z-index: 3;
			}

			&.date-calendar-input--opened {
				.date-calendar-input__input {
					background-color: $grey-light;
				}
			}

			&.travellers-room-button--opened {
				.travellers-room-button__input {
					background-color: $grey-light;
				}
			}
		}

		&:first-of-type {
			@include gridle_state(md) {
				border-left: none;
			}
		}

		.advanced-select__select__value-container {
			.sdp-search-form__suggestion-picto {
				display: none;
			}
		}

		& label {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&--fullsize {
			width: 100%;

			@include gridle_state(md) {
				width: unset;
			}
		}
	}

	&__footer {
		width: 100%;
		margin-top: 8px;

		@include gridle_state(md) {
			width: 180px;
			margin-top: 0;
			box-sizing: border-box;
			flex: unset;
		}

		.button {
			letter-spacing: 0;
		}

		svg {
			width: 26px;
			height: 26px;
		}
	}

	&__suggestion {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		overflow-x: hidden;

		&-picto {
			margin-right: 10px;
			display: flex;
			align-items: center;

			svg {
				width: 20px;
				max-height: 18px;
			}
		}

		&-label {
			@extend %font-bold;
		}

		&-text {
			display: flex;
			flex-direction: column;
		}

		&-first-label {
			font-weight: bold;
		}

		&-second-label {
			color: $grey-dark;
		}
	}

	&__container {
		.location-inspiration {
			position: relative;
			padding: 16px 16px 0;
			margin-top: 32px;

			&::before {
				position: absolute;
				content: "";
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 100px;
				height: 1px;
				background-color: $grey-medium;
			}

			&__merch {
				padding: 0;

				.relative-link__text,
				.location-inspiration__merch-cta,
				.background-progressive-image {
					width: 100%;
				}
			}

			&__sales {
				padding: 0 0 32px;

				.lookalike-product {
					width: 100%;
				}
			}
		}
	}

	.alert-message {
		margin: 16px;
		text-align: center;

		&__message {
			justify-content: center;
		}

		@include gridle_state(md) {
			margin: 20px 0 0;
		}
	}

	.advanced-select__label,
	.date-calendar-input__label,
	.travellers-room-button__label {
		top: 18px;
		@include gridle_state(md) {
			top: 15px;
		}
	}

	.date-calendar-input--touched .date-calendar-input__label,
	.advanced-select--touched .advanced-select__label,
	.travellers-room-button--touched .travellers-room-button__label {
		top: 8px;
		font-weight: bold;

		@include gridle_state(md) {
			top: 5px;
		}
	}

	.advanced-select__select {
		height: 56px;
		@include gridle_state(md) {
			height: 45px;
		}
	}

	.advanced-select__select__value-container {
		padding-top: unset;
		padding-bottom: unset;
		height: 100%;
	}

	.advanced-select__select__input-container {
		height: 30px;
		margin-top: 13px;
	}

	.advanced-select__select .advanced-select__select__control,
	.date-calendar-input__input,
	.travellers-room-button__input {
		border: none !important;
		box-shadow: none;
		font-size: 1.4rem;
		align-items: flex-end;
		min-height: 30px;
		height: 30px;
		line-height: 30px;
	}

	.advanced-select__select .advanced-select__select__control {
		display: flex;
		border-bottom-left-radius: $border-radius-big;
		border-top-left-radius: $border-radius-big;
		cursor: pointer;
	}

	.advanced-select__select .advanced-select__select__control--is-focused {
		background-color: $grey-light;
	}

	.date-calendar-input__input,
	.advanced-select__select__single-value,
	.travellers-room-button__input {
		margin-top: 18px;
		width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		@include gridle_state(md) {
			margin-top: 13px;
		}
	}
}
